import React, { Component } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
class Like extends Component {
  state = {};
  render() {
    if (this.props.liked) return <AiFillHeart onClick={this.props.onClick} />;
    else if (!this.props.liked)
      return <AiOutlineHeart onClick={this.props.onClick} />;
  }
}

export default Like;
